import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase  from 'firebase/app';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private afa: AngularFireAuth,
    private dataService: DataService
  ) { }

  async logado(){
    return new Promise((resolve, reject) =>{
      this.afa.onAuthStateChanged((user) =>{
        if(user){
          resolve(user);
        }else{
          reject(user);
        }
      })
    });
  }

  async login(email, senha){
	  return new Promise((resolve, reject) =>{
      this.afa.signInWithEmailAndPassword(email, senha).then(
    		(res) => resolve(res),
    		(err) => reject(err)
    	);
    });
  }

  async logout(){
    await this.dataService.removeStorage('usuario');
    await this.dataService.removeStorage('cards');
  	this.afa.signOut();
  }

  async register(email, senha){
  	return new Promise((resolve, reject)=>{
  		this.afa.createUserWithEmailAndPassword(email, senha).then(
  			(res) => resolve(res),
  			(err) => reject(err)
  		);
  	});
  }

  async resetUserPassword(senhaAntiga, email, senha) {
    return new Promise(async (resolve, reject) =>{
      const cpUser: any = await this.afa.currentUser;
      const credentials = firebase.default.auth.EmailAuthProvider.credential(cpUser.email, senhaAntiga);
      cpUser.reauthenticateWithCredential(credentials).then((success) => {
        cpUser.updatePassword(senha).then((data) =>{
          resolve(true);
        }).catch((err) =>{
          console.log(err);
          reject(false);
        })
      }).catch((e) =>{
        reject(e);
      });
    });
  }

  async forgotPassword(email){
    return new Promise(async (resolve, reject) =>{
      this.afa.sendPasswordResetEmail(email).then((success) =>{
        resolve(success);
      }).catch((err) =>{
        reject(err);
      });
    });
  }
}
